export const getActiveRequestID = (requestsItemsDataALL, itemID) => {
  return requestsItemsDataALL?.find(
    (r) => r._id === itemID
  )?._id
};

export const getActiveRequest = (requestsItemsDataALL, itemID) => {
  return requestsItemsDataALL?.find(
    (r) => r._id === itemID
  )
};