// @ts-nocheck
import React, { useState, useEffect, useContext } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
} from "react-table";
import { Icon } from "@lynx/ui-components";
import cn from "classnames";

import "./table-component.scss";
import { ModalContext } from "../modal/ModalContext";
import ChatModal from "ui/components/chat/ChatModal";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="search-block">
      <span>Search: </span>
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </div>
  );
}

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      className="select-filter-block"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

const TableComponent = ({
  data,
  dateColumns,
  formattingForDateColumns,
  showSearch,
  showRowOverlay,
  itemsWithSelectFilter = "",
  itemsToUseSorting, //must be an array
  setCellClassName = () => { },
}) => {
  const { showModal, hideModal } = useContext(ModalContext);

  //column structure
  const columns = React.useMemo(() => {
    const columns = data.length
      ? Object.keys(data[0]).map((item) => {
        let itemIsSelectFilter = false;
        let filterObject = null;
        if (Array.isArray(itemsWithSelectFilter)) {
          itemIsSelectFilter = itemsWithSelectFilter.includes(
            item.toLowerCase()
          );
        } else {
          itemIsSelectFilter = item.toLowerCase() === itemsWithSelectFilter;
        }
        if (itemIsSelectFilter) {
          filterObject = {
            Header: item,
            accessor: item.replaceAll(" ", ""),
            filter: "includes",
            Filter: SelectColumnFilter,
          };
        } else {
          let accessor = item.replaceAll(" ", "");

          filterObject = {
            Header: item,
            accessor,
          };

          if (dateColumns) {
            filterObject.formatCell = dateColumns.includes(accessor) // format cell if column in dateColumns
            ? formattingForDateColumns
            : undefined
          }

          if(dateColumns && dateColumns.includes(accessor)) {
            // you only use this sort method for columns with dates, when the dateColumns prop is passed
            filterObject.sortType = (rowA, rowB) => {
              let dateA = new Date(rowA.values[accessor]);
              let dateB = new Date(rowB.values[accessor]);
              return dateA > dateB ? 1 : -1;
            };
          }

        }
        return filterObject;
      })
    : null;

  return [{ Header: "none", columns: columns }];
  }, [data, itemsWithSelectFilter, formattingForDateColumns, dateColumns]);
  // Use the state and functions returned from useTable to build your UI

  const [sortBy, setSortBy] = useState([{ id: "id", desc: true }]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      initialState: { sortBy },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy // usesorting!
  );

  useEffect(() => {
    setSortBy(state.sortBy);
  }, [state.sortBy]);

  const setItemsToUseSorting = (currentColumn) => {
    if (itemsToUseSorting.length > 0 && data.length > 1) {
      const arrayToCheck = [
        ...itemsToUseSorting.map((item) => item.toLowerCase()),
      ];
      if (arrayToCheck.includes(currentColumn.Header.toLowerCase())) {
        return currentColumn.getSortByToggleProps();
      }
    }
  };

  // Render the UI for your table
  return (
    <table {...getTableProps()} className="table-wrapper">
      {showSearch && (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, ind) => {
              return (
                <th key={ind}>
                  <div
                    className="column-title"
                    {...column.getHeaderProps(
                      itemsToUseSorting
                        ? setItemsToUseSorting(column)
                        : undefined
                    )}
                  >
                    {column.isSorted && (
                      <span
                        className={cn({
                          ascending: column.isSortedDesc,
                          descending: !column.isSortedDesc,
                        })}
                      >
                        <Icon name="arrow_right" />
                      </span>
                    )}
                    <span>{column.render("Header")}</span>
                    {/* Render the columns filter UI */}
                  </div>
                  {column.filter && <div>{column.render("Filter")}</div>}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody
        {...getTableBodyProps()}
        className={cn({
          hide: data.at(-1) === "no-data",
        })}
      >
        {rows.map((row, i, array) => {
          prepareRow(row);
          const handleRowClick = (e) => {
            // Don't trigger row click when clicking on the three dots menu
            if (e.target.closest('.hover-content')) {
              return;
            }
            if (row.original.category === "Custom" || row.original.category === "Risk Settings") {
              showModal(<ChatModal hideModal={hideModal} item={row} />)
            }
          };
          return (
            <tr {...row.getRowProps()} key={i} onClick={handleRowClick}>
              {row.cells.map((cell, ind) => {
                const value = cell.column.formatCell
                ? cell.column.formatCell(cell.value)
                : cell.render("Cell");
                return (
                  <td
                    key={ind}
                    className={cn({
                      [setCellClassName(cell)]: setCellClassName(cell),
                    })}
                    {...cell.getCellProps()}
                  >
                    {value}
                  </td>
                );
              })}
              {showRowOverlay && showRowOverlay(row, i === array.length - 1)}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default React.memo(TableComponent);
